import {defineStore} from 'pinia'
import { DELETED, ENQUIRY_DELETED, LS_DELETED } from '~/constant/deletedTripPlan'
import { isEmpty } from '~/helper'

export const useTripPlanStore = defineStore('storeTripPlan',{
  state: () => ({
    dataTrip: {} as any,
    priceDisplayAPI: {} as any,
    priceDisplay: {},
    priceDisplayGallery: {},
    agentContactData: {
      apiLoaded: false,
      data: {}
    },
    product: [],
    originalRate: {},
    originalPriceEdit: {},
    transactionState: {},
    dataAffiliate: {},
    groupBookingState: {
      confirmed_travellers: 0,
      confirmed_traveller_details: [{ first_name: '', last_name: '' }],
      zeroGroupTrip: false,
    },
    dataEnquiry: {} as any,
  }),
  getters: {
    tripPlanRouteChange: (state) => (state?.dataTrip?.is_deleted || state?.dataTrip?.is_deactivated || state?.dataTrip?.is_deleted_enquiry),
    deletedType: (state) => {
      return state?.dataTrip?.is_deactivated ? LS_DELETED : state?.dataTrip?.is_deleted_enquiry ? ENQUIRY_DELETED : state?.dataTrip?.is_deleted ? DELETED : ''
    },
    designer: (state) => {
      if (isEmpty(state?.dataTrip)) return null
      return state?.dataTrip.designer || null
    },
    isCompleted: (state) => state?.dataTrip?.is_completed,
    isZeroGroupTrip: (state) => state?.groupBookingState?.zeroGroupTrip && state?.dataTrip?.is_group_enquiry,
    transactionData: (state) => state?.transactionState,
    groupBookingData: (state) => state?.groupBookingState
  },
  actions: {
    setDataTrip(response) {
      this.dataTrip = response;
    },
    setPriceDisplayAPI(response) {
      this.priceDisplayAPI = response
    },
    setPriceDisplay(response) {
      this.priceDisplay = response
    },
    setPriceDisplayGallery(response) {
      this.priceDisplayGallery = response
    },
    setAgentContact(response) {
      this.agentContactData = response
    },
    setProductData(response) {
      this.product = response
    },
    setOriginalRate(response) {
      this.originalRate = response
    },
    resetDataTrip() {
      this.dataTrip = {}
    },
    setOriginalPriceEdit(response) {
      this.originalPriceEdit = response
    },
    setTransactionData(data) {
      this.transactionState = data
    },
    setDataAffiliate(response) {
      this.dataAffiliate = response;
    },
    setGroupBookingData(data) {
      this.groupBookingState = { ...this.groupBookingState, ...data }
    },
    setEnquiryData(data) {
      this.dataEnquiry = data
    },
  }
})
